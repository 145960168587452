export default [{
  path: '/aquisicao-credito',
  name: 'aquisicao-credito',
  component: () => import('@/app/aquisicao-credito/AquisicaoCredito.vue'),
  meta: {
    resource: 'aquisicao_credito',
    action: 'create',
    pageTitle: 'Aquisição de créditos',
    breadcrumb: [],
  },
}]
