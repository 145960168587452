export default [{
  path: '/relatorio-consumo',
  name: 'relatorio-consumo',
  component: () => import('@/app/relatorio-consumo/RelatorioConsumoView.vue'),
  meta: {
    resource: 'relatorio_consumo',
    action: 'create',
    pageTitle: 'Histórico de consumo de créditos',
    breadcrumb: [],
  },
}]
