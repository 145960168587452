import acesso from '@app/acesso/router'
import emailConfig from '@app/email-config/shared/router'
import dashboard from '@app/analitico/shared/router'
import empresas from '@app/empresas/shared/router'
import usuarioInterno from '@app/usuario/shared/router'
import certificado from '@app/certificado-digital/shared/router'
import caixaPostal from '@app/caixa-postal/shared/router'
// import caixaPostalEstadual from '@app/caixa-postal-estadual/shared/router'
import cndFederal from '@app/cnd-federal/shared/router'
import cndFgts from '@app/cnd-fgts/shared/router'
import cndTrabalhista from '@app/cnd-trabalhista/shared/router'
// import cndMte from '@app/cnd-mte/shared/router'
import cndMunicipal from '@app/cnd-municipal/shared/router'
import cndEstadual from '@app/cnd-estadual/shared/router'
import situacaoFiscal from '@app/situacao-fiscal/shared/router'
import perfilEmpresa from '@app/perfil-empresa/shared/router'
import parcelamento from '@app/parcelamentos/shared/router'
import declaracaoDctfWeb from '@app/declaracao-dctf-web/shared/router'
import parcelamentoPgfn from '@/app/parcelamentos/parcelamento-pgfn/shared/router'
import simpPrevidenciario from '@/app/parcelamentos/parcelamento-simplificado-previdenciario/shared/router'
import parcelamentoRelp from '@app/parcelamentos/parcelamento-relp/router'
import portalDocumento from '@app/portal-documento/shared/router'
import parcelamentoNaoPrevidenciario from '@app/parcelamentos/parcelamento-nao-previdenciario/router'
import sublimite from '@app/sublimite/shared/router'
import das from '@/app/das/shared/router'
import comprovantePagamento from '@/app/comprovante-pagamento/shared/router'
import configuracaoParametro from '@/app/parametros-sistema/shared/router'
import cndPortalDocumento from '@/app/cnd-portal-documento/shared/router'
import planoView from '@/app/plano/router'
import aquisicaoCredito from '@/app/aquisicao-credito/shared/router'
import relatorioConsumo from '@/app/relatorio-consumo/shared/router'

export default [
  ...acesso,
  ...dashboard,
  ...empresas,
  ...usuarioInterno,
  ...certificado,
  ...caixaPostal,
  // ...caixaPostalEstadual,
  ...emailConfig,
  ...cndFederal,
  ...cndFgts,
  ...cndTrabalhista,
  // ...cndMte,
  ...cndEstadual,
  ...cndMunicipal,
  ...situacaoFiscal,
  ...perfilEmpresa,
  ...parcelamento,
  ...parcelamentoRelp,
  ...declaracaoDctfWeb,
  ...portalDocumento,
  ...parcelamentoNaoPrevidenciario,
  ...sublimite,
  ...parcelamentoPgfn,
  ...das,
  ...simpPrevidenciario,
  ...comprovantePagamento,
  ...configuracaoParametro,
  ...cndPortalDocumento,
  ...planoView,
  ...aquisicaoCredito,
  ...relatorioConsumo,
]
